<template>
    <div style="margin-top: 50px">
        <el-row :gutter="20">
            <el-col :span="8">
                <el-card class="box-card" style="height: 650px">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-order"></i> <span>APP介绍</span>
<!--                        <el-button type="primary" @click="upload_app()" style="float: right">点击下载</el-button>-->
                        <a href="videos/huaqiang-local.apk" download="huaqiang-local.apk" style="float: right">点击下载app</a>

                    </div>
                    <div>
<!--                        <el-collapse accordion v-model="activeName">-->
<!--                          <el-collapse-item name="1">-->
<!--                            <template slot="title">-->
<!--                              <div style="font-size: 16px">APP简介</div>-->
<!--                            </template>-->

                            <div>视频即时分析系统APP是一款基于人工智能技术的安全分析工具，旨在帮助用户能随时识别和分析潜在的安全风险。</div>
                            <br>
                            <div>该系统支持多种类型的安全事件识别，包括：</div>
                            <div>摔倒检测：该任务可用于检测监控区域内的人员是否发生了摔倒事件。</div>
                            <div>明火检测：该任务可用于检测监控区域内是否出现明火，如火灾等。</div>
                            <div>烟雾检测：该任务可用于检测监控区域内是否出现烟雾，如火灾等。</div>
                            <div>污水排放检测：该任务可用于检测监控区域内是否出现排放污水等违法行为。</div>
                            <div>.....</div>
                            <br>
                            <div>我们希望本APP能够为您提供高效、准确的监控和报警服务，能随时通过手机查看最新的报警信息，保障您的人员和财产的安全。如有任何问题和建议，请随时联系系统管理员。</div>
<!--                          </el-collapse-item>-->
<!--                        </el-collapse>-->
                    </div>
                </el-card>

            </el-col>
            <el-col :span="8">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-picture"></i>
                        <span>APP示意图</span>
<!--                        <el-button style="float: right; padding: 3px 0" type="text"><i class="el-icon-s-fold"></i>-->
<!--                        </el-button>-->
                    </div>
                    <div class="block">
                        <el-carousel trigger="click" height="550px" style="width: 600px">
                          <el-carousel-item v-for="item in items" :key="item.index">
                              <div style="text-align: center">
                                <img :src=item.img_url style="width: 400px;height: 540px">
                              </div>

<!--                              <el-image src="../src/login-bg.jpg"></el-image>-->
<!--                            <h3 class="small"></h3>-->
                          </el-carousel-item>
                        </el-carousel>
                      </div>

                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "AppIntroduction",
        data() {
            return {
                activeName: "1",
                items:[{img_url:require('../assets/img/app_introduction_1.jpg'),index:0},
                    {img_url:require('../assets/img/app_introduction_2.jpg'),index: 1},
                    {img_url:require('../assets/img/app_introduction_3.jpg'),index: 2},
                    {img_url:require('../assets/img/app_introduction_4.jpg'),index: 3}]
            }
        },
        methods: {
            upload_app(){

            }
        }
    }
</script>

<style scoped>
    .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
     /*background-color: #99a9bf;*/
  }

  .el-carousel__item:nth-child(2n+1) {
     /*background-color: #d3dce6;*/
  }

</style>